import { createContext, useCallback, useContext, useMemo } from 'react'
import { useQuery, type ApolloError } from 'apollo-client'

import subscriptionQuery from './graph/subscription.graphql'
import type { SubscriptionPayload } from './graph/subscription.graphql'


export type ModifiedSubscription = ReturnType<typeof modifySubscription>

const modifySubscription = (data: Partial<SubscriptionPayload['currentUser']['data']>) => {
  const { personalInfo, subscription, canApplyTossInOffer, caseSubscription, candleSubscription, driftSubscription } = data
  const { name: statusName, subscribed = false } = subscription?.status || {}
  const addressStatus = personalInfo?.addressInfo?.status

  const isMonthlyPlan = subscription?.plan?.name === 'MONTHLY'
  const isUpgradableFromMonthlyPlan = isMonthlyPlan && subscription?.stateOptions?.canUpgrade

  const isShippingAddressInvalid = addressStatus === 'UNDELIVERABLE' || addressStatus === 'UNABLE_MANUALLY_VALIDATE'
  const isShippingAddressUndeliverable = addressStatus === 'UNDELIVERABLE' || statusName === 'Undeliverable'

  return {
    ...subscription,
    isMonthlyPlan,
    isUpgradableFromMonthlyPlan,
    hasNeverSubscribed: !statusName || statusName === 'NotSubscribed', // subscription is "null" if user isn't subscribed yet
    isSubscribed: subscribed,
    isActive: statusName === 'Active',
    isCancelled: statusName === 'Cancelled',
    isOnHold: statusName === 'OnHold',
    isOnTechnicalHold: statusName === 'OnTechnicalHold',
    isPaymentInProgress: statusName === 'PaymentInProgress', // not subscribed, but subscription session is in progress
    isPending: statusName === 'Pending',
    isPendingChangePlan: statusName === 'PendingChangePlan',
    isPendingUpgrade: statusName === 'PendingUpgrade',
    isUndeliverable: statusName === 'Undeliverable',
    isUnpaid: statusName === 'Unpaid',
    isShippingAddressInvalid,
    isShippingAddressUndeliverable,
    canApplyTossInOffer,
    hasCaseSubscription: caseSubscription?.isSelected || false,
    hasCandleSubscription: candleSubscription?.isSelected || false,
    hasDriftSubscription: driftSubscription?.isSelected || false, // ATTN doesn't work for now
    isCaseSubscriptionAvailable: caseSubscription?.isEnabled && !caseSubscription?.isSelected,
    isCandleSubscriptionAvailable: candleSubscription?.isEnabled && !candleSubscription?.isSelected,
  }
}

type SubscriptionContextValue = {
  subscription: ModifiedSubscription
  error: ApolloError
  getSubscriptionFromCache: () => ModifiedSubscription
  isFetching: boolean
}

const SubscriptionContext = createContext<SubscriptionContextValue>(null)

export const SubscriptionProvider: React.CFC = ({ children }) => {
  const {
    data: subscriptionData,
    isFetching,
    error,
    client,
  } = useQuery(subscriptionQuery, {
    fetchPolicy: 'cache-first',
  })

  // ATTN to get updated data, you should add refetchQueries and awaitRefetchQueries to your mutation
  const getSubscriptionFromCache = useCallback((): ModifiedSubscription => {
    const data = client.cache.readQuery({
      query: subscriptionQuery,
    })

    if (!data) {
      return null
    }

    return modifySubscription(data.currentUser.data)
  }, [ client ])

  // ATTN the data is null, when data is loading, but it is defined when user doesn't have subscription
  const subscription = useMemo<ModifiedSubscription>(() => {
    if (!subscriptionData?.currentUser?.data) {
      return null
    }

    return modifySubscription(subscriptionData.currentUser.data)
  }, [ subscriptionData ])

  const value: SubscriptionContextValue = {
    subscription,
    isFetching,
    error,
    getSubscriptionFromCache,
  }

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  )
}

export const useSubscription = () => useContext(SubscriptionContext)

// returns true when subscription query is in progress, updating info
export const useIsSubscriptionUpdating = () => {
  const { isFetching } = useQuery(subscriptionQuery, {
    fetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  })
  return isFetching
}
